<template>
  <div class="forgot-password">
    <b-container class="pt-4">
      <b-row>
        <b-col cols="12" md="6">
          <h3 class="title">{{$t('Forgot password')}}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <ValidationObserver ref="validator">
            <form @submit.prevent="reset">
              <div class="email">{{email}}</div>

              <RFValidation :name="$t('New Password')" rules="required|minLength|password">
                <RFInput :label="$t('New Password')" type="password" v-model="newPassword"/>
              </RFValidation>

              <RFValidation :name="$t('Confirm Password')" rules="required">
                <RFInput :label="$t('Confirm Password')" type="password" v-model="confirmPassword"/>
              </RFValidation>

              <RFButton class="mt-4" :label="$t('Reset Password')" v-if="!loading"/>
              <RFLoader class="mt-4" :size="30" color="#24425B" v-else />
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'

import {store} from '@/stores'
import {userService} from '@/services'
import {isAdmin} from '@/helpers/functions'

export default {
  name: 'ForgotPassword',
  components: {
    RFInput,
    RFButton,
    RFLoader,
    RFValidation,
  },
  async beforeRouteEnter(to, from, next) {
    const loggedIn = store.state.userStore.loggedIn

    if (loggedIn) {
      const userRoles = store.state.userStore.user.roles

      if (isAdmin(userRoles)) {
        next({ name: 'admin' })
      } else {
        next({ name: 'profile' })
      }
    } else {
      const { data: isValidToken } = await userService.validateTokenForgot(to.query.email, to.query.token)

      if (!isValidToken) {
        next({ name: 'homepage' })
      } else {
        next()
      }
    }
  },
  data() {
    return {
      email: null,
      newPassword: null,
      confirmPassword: null,
      loading: false,
    }
  },
  beforeMount() {
    this.email = this.$route.query.email
  },
  methods: {
    async reset() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        if (this.newPassword !== this.confirmPassword) {
          this.$toasted.error(this.$i18n.t(`New password and confirm password doesn't match`))
          return false
        }

        this.loading = true

        const auth = {
          newPassword: this.newPassword,
          token: this.$route.query.token,
        }

        const { status } = await userService.addPassword(this.$route.query.id, auth)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Password successfully restored!`))
          this.$router.push('/')
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot-password {
  height: calc(100vh - 86px);
  padding-top: $menu-height;
  min-height: 80vh;

  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
  }
  .email {
    @include font-style($montserrat, 'medium', $font-14);
  }
}
</style>
